import { Component, OnInit } from '@angular/core';
import { ModuleService } from './../../module.service';
import { FormBuilder, Validators, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient,HttpHeaders, HttpBackend, HttpEventType, HttpResponse} from '@angular/common/http';
declare var $;

@Component({
  selector: 'app-add-ivrmenu',
  templateUrl: './add-ivrmenu.component.html',
  styleUrls: ['./add-ivrmenu.component.scss']
})
export class AddIVRMenuComponent implements OnInit {
  error: string;
  addIVRMenuForm: FormGroup;
  private http: HttpClient;
  showLoadingIndicator: any;
  msbapTitle = 'Audio Title';
  msbapAudioUrl = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/fsdffsdf.mp3';   
     
  msbapDisplayTitle = false; 
  msbapDisplayVolumeControls = true;  
  constructor(
    private fb: FormBuilder,
    private moduleService: ModuleService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService:ToastrService,
    handler: HttpBackend,
   
  ) {
    this.http = new HttpClient(handler);
  }
  
  menuname:any;
  campaigns:any;
  // msbapTitle:any;
  audio:any;
  alltarget:any;
  allivrroutingplan:any;
  public currentCompany:any;
  public allBuyers:any;
  ngOnInit() {

    // this.showLoadingIndicator= true;
    // setTimeout(()=>{this.showLoadingIndicator= false; },1000);
    this.addIVRMenuForm = this.fb.group({
      user_uuid: [''],            
      name: ['',Validators.required],            
      noofretries: ['',Validators.required],            
      timeout: ['',Validators.required],    
      forward_to: [''],        
      // openingmenu_forward_to: [''],        
      ivr_options: [''],        
      // openingmenu_forward_tonumber: [''],        
      default_response: [''],        
      buyer: [''],        
      
      // Select Audio UUID
      audio_uuid_0: [''],        
      audio_uuid_1: [''],        
      audio_uuid_2: [''],        
      audio_uuid_3: [''],        
      audio_uuid_4: [''],        
      audio_uuid_5: [''],        
      audio_uuid_6: [''],        
      audio_uuid_7: [''],        
      audio_uuid_8: [''],        
      audio_uuid_9: [''],        
      audio_uuid_10: [''],        
      
      // Upload Audio File
      upload_audio_0: [''],          
      upload_audio_1: [''],        
      upload_audio_2: [''],        
      upload_audio_3: [''],        
      upload_audio_4: [''],        
      upload_audio_5: [''],        
      upload_audio_6: [''],        
      upload_audio_7: [''],        
      upload_audio_8: [''],        
      upload_audio_9: [''],        
      upload_audio_10: [''],        

      // Forward to Number or IVR Menu
      forward_to_0: [''],        
      forward_to_1: [''],        
      forward_to_2: [''],        
      forward_to_3: [''],        
      forward_to_4: [''],        
      forward_to_5: [''],        
      forward_to_6: [''],        
      forward_to_7: [''],        
      forward_to_8: [''],        
      forward_to_9: [''],        
      forward_to_10: [''],        

      // Forward to Number
      forwardto_phone_0: [''],        
      forwardto_phone_1: [''],        
      forwardto_phone_2: [''],        
      forwardto_phone_3: [''],        
      forwardto_phone_4: [''],        
      forwardto_phone_5: [''],        
      forwardto_phone_6: [''],        
      forwardto_phone_7: [''],        
      forwardto_phone_8: [''],        
      forwardto_phone_9: [''],        
      forwardto_phone_10: [''],        
     
      // Forward to sip address
      forwardto_sipaddress_0: [''],        
      forwardto_sipaddress_1: [''],        
      forwardto_sipaddress_2: [''],        
      forwardto_sipaddress_3: [''],        
      forwardto_sipaddress_4: [''],        
      forwardto_sipaddress_5: [''],        
      forwardto_sipaddress_6: [''],        
      forwardto_sipaddress_7: [''],        
      forwardto_sipaddress_8: [''],        
      forwardto_sipaddress_9: [''],        
      forwardto_sipaddress_10: [''],        
     
      // Forward to sip address
      forwardto_sipuri_0: [''],        
      forwardto_sipuri_1: [''],        
      forwardto_sipuri_2: [''],        
      forwardto_sipuri_3: [''],        
      forwardto_sipuri_4: [''],        
      forwardto_sipuri_5: [''],        
      forwardto_sipuri_6: [''],        
      forwardto_sipuri_7: [''],        
      forwardto_sipuri_8: [''],        
      forwardto_sipuri_9: [''],        
      forwardto_sipuri_10: [''],        
      
      // Forward to Campaign
      forwardto_campaign_0: [''],        
      forwardto_campaign_1: [''],        
      forwardto_campaign_2: [''],        
      forwardto_campaign_3: [''],        
      forwardto_campaign_4: [''],        
      forwardto_campaign_5: [''],        
      forwardto_campaign_6: [''],        
      forwardto_campaign_7: [''],        
      forwardto_campaign_8: [''],        
      forwardto_campaign_9: [''],        
      forwardto_campaign_10: [''],      
      
      forwardto_ivr_0: [''],        
      forwardto_ivr_1: [''],        
      forwardto_ivr_2: [''],        
      forwardto_ivr_3: [''],        
      forwardto_ivr_4: [''],        
      forwardto_ivr_5: [''],        
      forwardto_ivr_6: [''],        
      forwardto_ivr_7: [''],        
      forwardto_ivr_8: [''],        
      forwardto_ivr_9: [''],        
      forwardto_ivr_10: [''],        
     
      forwardto_routing_0: [''],        
      forwardto_routing_1: [''],        
      forwardto_routing_2: [''],        
      forwardto_routing_3: [''],        
      forwardto_routing_4: [''],        
      forwardto_routing_5: [''],        
      forwardto_routing_6: [''],        
      forwardto_routing_7: [''],        
      forwardto_routing_8: [''],        
      forwardto_routing_9: [''],        
      forwardto_routing_10: [''],        
     
      forwardto_ivrrouting_0: [''],        
      forwardto_ivrrouting_1: [''],        
      forwardto_ivrrouting_2: [''],        
      forwardto_ivrrouting_3: [''],        
      forwardto_ivrrouting_4: [''],        
      forwardto_ivrrouting_5: [''],        
      forwardto_ivrrouting_6: [''],        
      forwardto_ivrrouting_7: [''],        
      forwardto_ivrrouting_8: [''],        
      forwardto_ivrrouting_9: [''],        
      forwardto_ivrrouting_10: [''],        
    });

    this.addIVRMenuForm.patchValue({
      forward_to_0: 'FORWARD_TO_NUMBER',
      forward_to_1: 'FORWARD_TO_NUMBER',
      forward_to_2: 'FORWARD_TO_NUMBER',
      forward_to_3: 'FORWARD_TO_NUMBER',
      forward_to_4: 'FORWARD_TO_NUMBER',
      forward_to_5: 'FORWARD_TO_NUMBER',
      forward_to_6: 'FORWARD_TO_NUMBER',
      forward_to_7: 'FORWARD_TO_NUMBER',
      forward_to_8: 'FORWARD_TO_NUMBER',
      forward_to_9: 'FORWARD_TO_NUMBER',
      forward_to_10: 'FORWARD_TO_NUMBER',
    });  
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleService.getIVRMenus(this.currentCompany.uuid).subscribe(
        res => {
         this.menuname =res;
        },
        error => this.error = error
    ); 

    this.moduleService.getAlltarget(this.currentCompany.uuid).subscribe(
      res => {
       this.alltarget =res;
      },
      error => this.error = error
  ); 
    
  this.moduleService.allivrroutingplan(this.currentCompany.uuid).subscribe(
      res => {
        // console.log(res);
       this.allivrroutingplan =res;
      },
      error => this.error = error
  ); 
   
   this.moduleService.getallcampaindata(this.currentCompany.uuid).subscribe(
        res => {
         this.campaigns =res;
        },
        error => this.error = error
    ); 
  
    this.moduleService.getallbuyers(this.currentCompany.uuid).subscribe(
        res => {
         this.allBuyers =res;
        },
        error => this.error = error
    ); 

    this.getaudio();

    // console.log(this.audio);
  }

  selectedIVR_Options: any;
  IVR_Options(event: any) {
    this.selectedIVR_Options = event.target.value;
  }

  forward_0:string = 'FORWARD_TO_NUMBER';
  forward_1:string = 'FORWARD_TO_NUMBER';
  forward_2:string = 'FORWARD_TO_NUMBER';
  forward_3:string = 'FORWARD_TO_NUMBER';
  forward_4:string = 'FORWARD_TO_NUMBER';
  forward_5:string = 'FORWARD_TO_NUMBER';
  forward_6:string = 'FORWARD_TO_NUMBER';
  forward_7:string = 'FORWARD_TO_NUMBER';
  forward_8:string = 'FORWARD_TO_NUMBER';
  forward_9:string = 'FORWARD_TO_NUMBER';
  forward_10:string = 'FORWARD_TO_NUMBER';
  forward_to(event: any,i: number) {
    if(i==0){
    this.forward_0 = event.target.value;
    }
    if(i==1){
    this.forward_1 = event.target.value;
    }
    if(i==2){
    this.forward_2 = event.target.value;
    }
    if(i==3){
    this.forward_3 = event.target.value;
    }
    if(i==4){
    this.forward_4 = event.target.value;
    }
    if(i==5){
    this.forward_5 = event.target.value;
    }
    if(i==6){
    this.forward_6 = event.target.value;
    }
    if(i==7){
    this.forward_7 = event.target.value;
    }
    if(i==8){
    this.forward_8 = event.target.value;
    }
    if(i==9){
    this.forward_9 = event.target.value;
    }
    if(i==10){
    this.forward_10 = event.target.value;
    }
  }

  url_0:string = '';
  url_1:string = '';
  url_2:string = '';
  url_3:string = '';
  url_4:string = '';
  url_5:string = '';
  url_6:string = '';
  url_7:string = '';
  url_8:string = '';
  url_9:string = '';
  url_10:string = '';
  
  audioname:string;
  audiochange(args,count){ 
    // alert(count);
    // console.log(args);return false;
    this.audioname = args.target.options[args.target.selectedIndex].text;
    if(count == 0){
      this.url_0 = '';
    this.url_0 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+this.audioname;
    }
    
    // return false;

    if(count == 1){
      this.url_1 = '';
      this.url_1 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+this.audioname;
    }

    if(count == 2){
      this.url_2 = '';
      this.url_2 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+this.audioname;
    }

    if(count == 3){
      this.url_3 = '';
      this.url_3 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+this.audioname;
    }

    if(count == 4){
      this.url_4 = '';
      this.url_4 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+this.audioname;
    }

    if(count == 5){
      this.url_5 = '';
      this.url_5 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+this.audioname;
    }

    if(count == 6){
      this.url_6 = '';
      this.url_6 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+this.audioname;
    }

    if(count == 7){
      this.url_7 = '';
      this.url_7 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+this.audioname;
    }

    if(count == 8){
      this.url_8 = '';
      this.url_8 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+this.audioname;
    }

    if(count == 9){
      this.url_9 = '';
      this.url_9 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+this.audioname;
    }

    if(count == 10){
      this.url_10 = '';
      this.url_10 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+this.audioname;
    }
    // alert(this.url);
  } 

  openingmenuforward:any;
  openingmenu_forward(event: any) {
    this.openingmenuforward = event.target.value;
  }

  getaudio(){
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleService.getIVRAudio(this.currentCompany.uuid,0).subscribe(
      res => {
        
       this.audio = res;
      //  console.log(this.audio);
      },
      error => this.error = error
    ); 
  }
  
  itr:any;
  selectedIVR(){
    let array = [];
    if(!this.selectedIVR_Options){
      this.selectedIVR_Options=1;
    }
    for (let i = 0; i < this.selectedIVR_Options; i++) {
      array.push(i);
    }
    
    return array;
  }

  // selectedFile(event){
  //   alert(event);
  //   console.log(event);
  // }

  setaudioselected(uuid,count){
    if(count==0){
      this.addIVRMenuForm.patchValue({
        audio_uuid_0: uuid,
        });
    }
    if(count==1){
      this.addIVRMenuForm.patchValue({
        audio_uuid_1: uuid,
        });  
    }
    
    if(count==2){
      this.addIVRMenuForm.patchValue({
        audio_uuid_2: uuid,
        });  
    }

    if(count==3){
      this.addIVRMenuForm.patchValue({
        audio_uuid_3: uuid,
        });  
    }

    if(count==4){
      this.addIVRMenuForm.patchValue({
        audio_uuid_4: uuid,
        });  
    }
    if(count==5){
      this.addIVRMenuForm.patchValue({
        audio_uuid_5: uuid,
        });  
    }
    if(count==6){
      this.addIVRMenuForm.patchValue({
        audio_uuid_6: uuid,
        });  
    }
    if(count==7){
      this.addIVRMenuForm.patchValue({
        audio_uuid_7: uuid,
        });  
    }
    if(count==8){
      this.addIVRMenuForm.patchValue({
        audio_uuid_8: uuid,
        });  
    }
    if(count==9){
      this.addIVRMenuForm.patchValue({
        audio_uuid_9: uuid,
        });  
    }
    if(count==10){
      this.addIVRMenuForm.patchValue({
        audio_uuid_10: uuid,
        });  
    }
  }

  
  formdata = new FormData();
  // currentFileUpload: File;

  selectFile(event,count) {
    this.showLoadingIndicator= true;
    // alert(count);return false;
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    // this.currentFileUpload = event.target.files.item(0);
    // console.log(this.currentFileUpload);return false;
  
    this.moduleService.uploadfile(event.target.files.item(0),this.currentCompany.uuid,0).subscribe(
      res => {
        // console.log(JSON.parse(sessionStorage.getItem('progress')));
          // this.progress.percentage = res;
        //        console.log(res.status);
        //  return false; 
        // if (res.status != 200) {
          // alert('ok');
         
        // }
        if (res.status !== 'error' && event.target.files.item(0).name!='' && res.body) {
        //   console.log(res.status);
        //  return false;         
          if(res.status === 200){
            this.showLoadingIndicator= false;
          }

          this.toastrService.success('Audio Uploaded Succssfully');
          // return false;
          this.getaudio();
          this.setaudioselected(res.body.uuid,count);
          if(res.status == 200)
          if(count == 0){
            this.url_0 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+event.target.files.item(0).name;            
          }

          if(count == 1){
            this.url_1 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+event.target.files.item(0).name;            
          }

          if(count == 2){
            this.url_2 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+event.target.files.item(0).name;            
          }

          if(count == 3){
            this.url_3 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+event.target.files.item(0).name;            
          }

          if(count == 4){
            this.url_4 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+event.target.files.item(0).name;            
          }

          if(count == 5){
            this.url_5 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+event.target.files.item(0).name;            
          }

          if(count == 6){
            this.url_6 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+event.target.files.item(0).name;            
          }

          if(count == 7){
            this.url_7 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+event.target.files.item(0).name;            
          }

          if(count == 8){
            this.url_8 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+event.target.files.item(0).name;            
          }

          if(count == 9){
            this.url_9 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+event.target.files.item(0).name;            
          }          
         
          if(count == 10){
            this.url_10 = 'https://d23at3rs98zo0i.cloudfront.net/ivr-audio/'+event.target.files.item(0).name;            
          }          
        }else{
          if(res.status === 'error'){
            this.toastrService.error('Failed To Add');
            }
        }
      },
      error => this.error = error,
    );
  }
  
  submitted = false;
  get f() { return this.addIVRMenuForm.controls; }  
  onSubmit () {
    // const formData = new FormData();      
    this.submitted = true;
    if (this.addIVRMenuForm.invalid) {
        return;
    }
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.addIVRMenuForm.get("user_uuid").setValue(currentUser.uuid);
    this.moduleService.addIVR(this.addIVRMenuForm.value,this.currentCompany.uuid).subscribe(
      res => {
        // console.log(res); return false;
        if (res.status !== 'error') {
          this.toastrService.success('IVR Menu Added Successfully');
          this.router.navigate(['/ivr-menu']);
        }else{
          this.toastrService.error('Failed To Add');
        }
      },
      error => this.error = error,
    );     
  }

  _keyPress(event: any) {
    const isNumericInput = (event) => {
      const key = event.keyCode;
      return ((key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
      );
    };
    
    const isModifierKey = (event) => {
      const key = event.keyCode;
      return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
        (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
        (key > 36 && key < 41) || // Allow left, up, right, down
        (
          // Allow Ctrl/Command + A,C,V,X,Z
          (event.ctrlKey === true || event.metaKey === true) &&
          (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
        )
    };
    
    const enforceFormat = (event) => {
      // Input must be of a valid number format or a modifier key, and not longer than ten digits
      if(!isNumericInput(event) && !isModifierKey(event)){
        event.preventDefault();
      }
    };
    
    const formatToPhone = (event) => {
      if(isModifierKey(event)) {return;}    
      const target = event.target;
      const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
      const zip = input.substring(0,3);
      const middle = input.substring(3,6);
      const last = input.substring(6,10);
    
      if(input.length > 6){target.value = `(${zip}) ${middle}-${last}`;}
      else if(input.length > 3){target.value = `(${zip}) ${middle}`;}
      else if(input.length > 0){target.value = `(${zip}`;}
    };
    const forwardto_phone_0no = document.getElementById('forwardto_phone_0');
    const forwardto_phone_1no = document.getElementById('forwardto_phone_1');
    const forwardto_phone_2no = document.getElementById('forwardto_phone_2');
    const forwardto_phone_3no = document.getElementById('forwardto_phone_3');
    const forwardto_phone_4no = document.getElementById('forwardto_phone_4');
    const forwardto_phone_5no = document.getElementById('forwardto_phone_5');
    const forwardto_phone_6no = document.getElementById('forwardto_phone_6');
    const forwardto_phone_7no = document.getElementById('forwardto_phone_7');
    const forwardto_phone_8no = document.getElementById('forwardto_phone_8');
    const forwardto_phone_9no = document.getElementById('forwardto_phone_9');
    const forwardto_phone_10no = document.getElementById('forwardto_phone_10');

      if(forwardto_phone_0no){
        forwardto_phone_0no.addEventListener('keydown',enforceFormat);
        forwardto_phone_0no.addEventListener('keyup',formatToPhone);
      }
      if(forwardto_phone_1no){
        forwardto_phone_1no.addEventListener('keydown',enforceFormat);
        forwardto_phone_1no.addEventListener('keyup',formatToPhone);
      }
      if(forwardto_phone_2no){
        forwardto_phone_2no.addEventListener('keydown',enforceFormat);
        forwardto_phone_2no.addEventListener('keyup',formatToPhone);
      }
      if(forwardto_phone_3no){
        forwardto_phone_3no.addEventListener('keydown',enforceFormat);
        forwardto_phone_3no.addEventListener('keyup',formatToPhone);
      }
      if(forwardto_phone_4no){
        forwardto_phone_4no.addEventListener('keydown',enforceFormat);
        forwardto_phone_4no.addEventListener('keyup',formatToPhone);
      }
      if(forwardto_phone_5no){
        forwardto_phone_5no.addEventListener('keydown',enforceFormat);
        forwardto_phone_5no.addEventListener('keyup',formatToPhone);
      }
      if(forwardto_phone_6no){
        forwardto_phone_6no.addEventListener('keydown',enforceFormat);
        forwardto_phone_6no.addEventListener('keyup',formatToPhone);
      }
      if(forwardto_phone_7no){
        forwardto_phone_7no.addEventListener('keydown',enforceFormat);
        forwardto_phone_7no.addEventListener('keyup',formatToPhone);
      }
      if(forwardto_phone_8no){
        forwardto_phone_8no.addEventListener('keydown',enforceFormat);
        forwardto_phone_8no.addEventListener('keyup',formatToPhone);
      }
      if(forwardto_phone_9no){
        forwardto_phone_9no.addEventListener('keydown',enforceFormat);
        forwardto_phone_9no.addEventListener('keyup',formatToPhone);
      }
      if(forwardto_phone_10no){
        forwardto_phone_10no.addEventListener('keydown',enforceFormat);
        forwardto_phone_10no.addEventListener('keyup',formatToPhone);
      }
      
  }
}
